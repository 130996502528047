@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.header {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  background-image: var(--bg-gradient);
  background-size: cover;
  background-position: center;
  padding: var(--padding-sm);
}

.header__container {
  height: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  align-content: center;
  color: var(--clr-neutral-100);
}

.header__title {
  font-family: var(--ff-primary);
  font-weight: var(--fw-bold);
  font-size: var(--fs-h1);
  text-transform: uppercase;
  z-index: 1;
  animation: fadeInFromBottom 1.2s cubic-bezier(0.5, 0.2, 0.3, 1);
}

.header__logo-container {
  align-self: center;
}

.header__logo {
  margin: 0 auto;
  height: auto;
  width: 14rem;
  animation: fadeInFromBottom 1200ms cubic-bezier(0.5, 0.2, 0.3, 1);
}

.header__taglines-container {
  font-family: var(--ff-primary);
  animation: fadeInFromBottom 1.2s cubic-bezier(0.5, 0.2, 0.3, 1);
}

.header__taglines-container h1 {
  font-size: var(--fs-h1);
  font-weight: var(--fw-regular);
  line-height: 3rem;
}

.header__taglines-container h2 {
  font-size: var(--fs-h2);
  font-weight: var(--fw-reg);
}

.header button {
  margin: 0 auto;
  animation: fadeInFromBottom 1.2s cubic-bezier(0.5, 0.2, 0.3, 1);
}
