.services {
  padding: var(--padding-sm);
  font-family: var(--ff-primary);
}

.services__container {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.services__container > h2 {
  height: 2rem;
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  text-align: center;
  text-transform: uppercase;
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.services__card {
  width: 100%;
  padding: var(--padding-md);
  text-align: center;
  font-family: var(--ff-primary);
  font-size: var(--fs-copy);
}

.services__icon {
  margin: 0 auto;
  padding: var(--padding-md);
}

.services__card > h2 {
  font-family: var(--ff-primary);
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  flex-basis: 100%;
}

.services__card > p {
  margin-top: var(--margin-sm);
}

@media (min-width: 675px) {
  .services__container {
    width: 78%;
    margin: 0 auto;
  }
}

@media (min-width: 1080px) {
  .services {
    padding: 0;
    padding-bottom: 1.2em;
  }

  .services__container {
    width: 90%;
    margin-top: 1.5em;
    margin-bottom: 3em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .services__container > h2 {
    grid-column: 1/4;
    text-align: center;
  }

  .services__card p {
    font-size: 1rem;
    text-align: left;
  }
}
