.contact {
  padding: var(--padding-sm);
  font-family: var(--ff-primary);
  font-size: var(--fs-h2);
}

.contact__container {
  width: 80%;
  margin: 0 auto;
  font-size: var(--fs-copy);
}

.contact__text {
  flex-basis: 50%;
}

.contact__container h2 {
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  text-align: center;
  text-transform: uppercase;
}

.contact__container p {
  margin: 2em 0;
}

.contact__form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-basis: 30%;
}

.contact__form input,
.contact__form textarea {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0.25em;
  background-color: var(--clr-neutral-100);
  padding: 0.2em;
}

.contact__form input:focus,
.contact__form textarea:focus {
  outline: none;
  border-bottom: 2px solid var(--clr-accent-300);
}

.contact__form input,
.contact__form textarea {
  margin: 0.25em 0;
}

.contact__form textarea {
  height: 5em;
}

.contact__form button {
  width: 4.2em;
  margin: 1.4em auto 0 auto;
}

.contact__form button:active {
  background-color: var(--clr-primary-400);
  width: 4em;
}

@media (min-width: 780px) {
  .contact {
    margin: var(--margin-lg);
  }

  .contact__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
